import getBrowserErrorType, {
  BrowserErrorType,
} from "@reservauto/react-shared/getBrowserErrorType";
import importRetry from "@reservauto/react-shared/importRetry";
import LocalStorageUtil from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import { User } from "oidc-client-ts";
import AppUrlParams from "./AppUrlParams";
import { isCurrentUrlB2C } from "./areas/backOfficeUser/b2c/b2cUtils";
import { LogDetailDTO } from "./areas/general/dto";
import { LogService } from "./areas/general/service";
import { expireLegacySessions } from "./areas/legacy/authenticationServices";
import authenticationService from "./frame/authenticationService";
import * as CriticalErrorPage from "./frame/CriticalErrorPage";
import "./shared/ActivityLog";
import appSettings from "./shared/appSettings";
import envSettings from "./shared/envSettings";
import branchStore from "./shared/stores/branchStore";
import userBOStore, { UserProfile } from "./shared/stores/userBOStore";

LocalStorageUtil.keyPrefix = "ReservautoReact";
Logging.initialize(
  (l) => new LogService().postLog(l),
  appSettings.ReservautoReactUri,
  envSettings.VITE_BUILDNUM,
  appSettings.Env,
  envSettings.VITE_LOGLEVELS
    ? envSettings.VITE_LOGLEVELS.toLowerCase().split(",")
    : [],
);

const appUrlParams = new AppUrlParams();

const browserLocaleId = window.navigator.language;
const isInIframe = window !== window.parent;

function showCriticalErrorPage(
  exception?: unknown,
  userMessage?: CriticalErrorPage.UserMessage,
  user?: User | null,
): void {
  let message = userMessage;
  if (!message && exception) {
    switch (getBrowserErrorType(exception)) {
      case BrowserErrorType.cache:
        message = "BrowserCache";
        break;
      case BrowserErrorType.plugin:
        message = "BrowserPlugin";
        break;
    }
  }

  try {
    CriticalErrorPage.mount(exception, message, user !== null);
  } catch (newException) {
    let logException = exception as { [name: string]: unknown };
    if (logException && typeof logException === "object") {
      logException.criticalErrorPageError = newException;
    } else {
      logException = {
        criticalErrorPageError: newException,
        error: logException,
      };
    }

    Logging.error(logException).finally(() =>
      window.navigateToFatalErrorPage(),
    );
  }
}

function signinRedirect(user: User | null, url?: string): void {
  const userProfile = user?.profile as UserProfile | undefined;
  const localeId =
    appUrlParams.locale ?? userProfile?.language ?? browserLocaleId;

  authenticationService
    .signinRedirect(localeId, url)
    .catch((ex) => showCriticalErrorPage(ex, undefined, user));
}

if (isCurrentUrlB2C()) {
  importRetry(() => import("./areas/backOfficeUser/b2c/B2C"))
    .then((b2c) => {
      if (typeof b2c.mount !== "function") {
        showCriticalErrorPage(
          new Error("b2c.mount is not a function"),
          "BrowserPlugin",
        );
      } else if (window.location.href.toLowerCase().includes("#error=")) {
        showCriticalErrorPage(
          new Error("B2C error: " + window.location.href),
          "B2C",
        );
      } else {
        b2c.mount(appUrlParams);
      }
    })
    .catch((ex) => showCriticalErrorPage(ex));
} else {
  try {
    switch (window.location.pathname.toLowerCase()) {
      case "/auth/logout":
        if (isInIframe) {
          window.top?.postMessage("iframe_sessionExpired", "*");
        } else {
          authenticationService
            .getUser()
            .then((user) => signinRedirect(user, "/"))
            .catch((ex) => showCriticalErrorPage(ex));
        }
        break;

      case "/legacy/forbidden":
        authenticationService
          .getUser()
          .then((user) => {
            showCriticalErrorPage(new Error("Forbidden"), "Forbidden", user);
          })
          .catch((ex) => showCriticalErrorPage(ex));
        break;

      case "/legacy/redirect": {
        const isLegacyUrlLocal =
          appUrlParams.url && !appUrlParams.url.includes("//");
        if (!isLegacyUrlLocal) {
          window.location.href = "/";
        } else if (isInIframe) {
          authenticationService
            .getUser()
            .then((user) => {
              branchStore.populate((user?.profile as UserProfile).branchId);
              let baseUrl = branchStore.get().reservautoLegacyUri;
              if (appUrlParams.type === "mvc") {
                baseUrl = appSettings.CommunautoReservautoMVCUri;
              }
              const pathSeparator = appUrlParams.url!.startsWith("/")
                ? ""
                : "/";
              document.location.replace(
                `${baseUrl}${pathSeparator}${appUrlParams.url}`,
              );
            })
            .catch((ex) => showCriticalErrorPage(ex));
        } else {
          const legacyUrl = encodeURIComponent(appUrlParams.url!);
          const titleParam = appUrlParams.title
            ? `&title=${encodeURIComponent(appUrlParams.title)}`
            : "";
          document.location.replace(
            `/legacy?type=${appUrlParams.type}&url=${legacyUrl}${titleParam}`,
          );
        }
        break;
      }

      case "/signin-callback":
        authenticationService
          .signinCallback()
          .then(async (newUser) => {
            await userBOStore.populate(newUser);
            expireLegacySessions();

            const isLocalUrl =
              newUser.state &&
              typeof newUser.state === "string" &&
              !newUser.state.includes("//");

            window.location.href = isLocalUrl ? newUser.state : "/";
          })
          .catch((ex) => {
            const timeErrors = [
              "exp is in the past",
              "iat is in the future",
              "nbf is in the future",
            ];
            if (timeErrors.some((e) => ex?.message?.startsWith(e))) {
              showCriticalErrorPage(ex, "DeviceTime");
            } else if (
              [
                "No matching state found in storage",
                "No state in response",
                "invalid_grant",
              ].includes(ex?.message)
            ) {
              authenticationService
                .getUser()
                .then((user) => signinRedirect(user, "/"))
                .catch((e) => showCriticalErrorPage(e));
            } else {
              showCriticalErrorPage(ex);
            }
          });
        break;

      case "/signout-callback":
        authenticationService
          .signoutCallback()
          .then(() => signinRedirect(null, "/"))
          .catch((ex) => {
            let logPromise: Promise<LogDetailDTO | void>;
            if (ex?.message === "No matching state found in storage") {
              logPromise = Logging.warning(ex);
            } else {
              logPromise = Logging.error(ex);
            }

            logPromise.finally(() => {
              signinRedirect(null, "/");
            });
          });
        break;

      case "/silent-callback":
        authenticationService
          .silentCallback()
          .catch((ex) => showCriticalErrorPage(ex));
        break;

      default:
        authenticationService
          .getUser()
          .then((user) => {
            if (!user || authenticationService.shouldRenewSession(user)) {
              signinRedirect(user);
            } else {
              importRetry(() => import("./App"))
                .then((app) => {
                  if (typeof app.mount !== "function") {
                    showCriticalErrorPage(
                      new Error("app.mount is not a function"),
                      "BrowserPlugin",
                    );
                  } else {
                    app.mount(user, appUrlParams);
                  }
                })
                .catch((ex) => showCriticalErrorPage(ex, undefined, user));
            }
          })
          .catch((ex) => showCriticalErrorPage(ex));
    }
  } catch (ex) {
    showCriticalErrorPage(ex);
  }
}
